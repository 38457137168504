import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";


function lazyView(view) {
  return () => import(/* webpackChunkName: "lazy-view-[request]" */ `../views/${view}`)
}

function restrictCouple() {
  if(!store.getters["auth/isMember"]) {
    return '/homenoivos';
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: lazyView('home/HomeView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/audicao/:id?',
    name: 'newAudicao',
    component: lazyView('audition/AudicaoView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/audicoes',
    name: 'audicoes',
    component: lazyView('audition/AudicaoListView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/escolha/:id?',
    name: 'escolha',
    component: lazyView('couple/EscolhasView.vue')
  },

  {
    path: '/cidade/:id?',
    name: 'newCidade',
    component: lazyView('city/CidadeView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/cidades',
    name: 'cidades',
    component: lazyView('city/CidadeListView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/musica/:id?',
    name: 'newMusica',
    component: lazyView('music/MusicView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/musicas',
    name: 'musicas',
    component: lazyView('music/MusicaListView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/cartilha',
    name: 'cartilha',
    component: lazyView('booklet/CartilhaView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/cerimonialista/:id?',
    name: 'newCerimonialista',
    component: lazyView('ceremonialist/CerimonialistaView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/cerimonialistas',
    name: 'cerimonialistas',
    component: lazyView('ceremonialist/CerimonialistaListView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/momento/:id?',
    name: 'newMomento',
    component: lazyView('moment/MomentoView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/momentos',
    name: 'momentos',
    component: lazyView('moment/MomentoListView.vue'),
    beforeEnter: [restrictCouple]
  },
  
  {
    path: '/local/:id?',
    name: 'newLocal',
    component: lazyView('location/LocalView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/locais',
    name: 'locais',
    component: lazyView('location/LocalListView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/login-integrantes',
    name: 'login-integrantes',
    component: lazyView('login/LoginIntegrantesView.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: lazyView('login/LoginNoivosView.vue')
  },

  {
    path: '/logout',
    name: 'logout',
    component: lazyView('logout/LogoutView.vue')
  },

  {
    path: '/homenoivos',
    name: 'homenoivos',
    component: lazyView('home/NoivosHomeView.vue')  
  },

  {
    path: '/info-noivos',
    name: 'infonoivos',
    component: lazyView('couple/NoivosPerfilView.vue')  
  },

  {
    path: '/sobre-nos',
    name: 'sobre-nos',
    component: lazyView('couple/AboutusView.vue')
  },

  {
    path: '/relatorios-musica',
    name: 'relatorios-musica',
    component: lazyView('report/RelatórioMusicaView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/cerimonia',
    name: 'cerimonia',
    component: lazyView('ceremony/CerimoniaListView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/relatorio-cerimoniais',
    name: 'relatorio-cerimoniais',
    component: lazyView('report/CerimonialistaRelatorioView.vue'),
    beforeEnter: [restrictCouple]
  },

  {
    path: '/relatorio-como-conheceram-o-qst',
    name: 'relatorio-como-conheceram-o-qst',
    component: lazyView('report/ComoConheceuOQSTRelatorioView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/contratos',
    name: 'contratos',
    component: lazyView('contract/ContratoListView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/contrato/code/:code?',
    name: 'contratoCode',
    component: lazyView('contract/ContratoView.vue')
  },
  {
    path: '/contrato/:id?',
    name: 'contrato',
    component: lazyView('contract/ContratoView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/cerimonias-pendentes',
    name: 'cerimoniasPendentes',
    component: lazyView('report/RelatorioCerimoniasPendentesView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/contador-cerimonias',
    name: 'contadorCerimonias',
    component: lazyView('report/RelatorioQtdeCerimoniasView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/fechamento-contratos',
    name: 'fechamentoContratos',
    component: lazyView('report/RelatorioQtdeContratosAssinados.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/dicas-de-musicas',
    name: 'dicasDeMusicas',
    component: lazyView('report/RelatorioDicasDeMusica.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/repertorio',
    name: 'repertorio',
    component: lazyView('report/RelatorioDicasDeMusica.vue')
  },
  {
    path: '/perfil-integrante',
    name: 'perfilIntegrante',
    component: lazyView('member/MemberProfile.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/recibos',
    name: 'recibos',
    component: lazyView('recipe/RecipeListView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/recibo/:id?',
    name: 'recibo',
    component: lazyView('recipe/RecipeView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/calculo-km',
    name: 'calculoKm',
    component: lazyView('kmcalculator/KmCalculatorView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/tabela-precos',
    name: 'tabelaPrecos',
    component: lazyView('weddingPrice/WeddingPriceView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/solistas',
    name: 'solistas',
    component: lazyView('soloist/SoloistListView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/solista/:id?',
    name: 'newSoloist',
    component: lazyView('soloist/SoloistView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/orcamentos',
    name: 'orcamentos',
    component: lazyView('quotation/QuotationListView.vue'),
    beforeEnter: [restrictCouple]
  },
  {
    path: '/orcamento/:id?',
    name: 'newQuotation',
    component: lazyView('quotation/QuotationView.vue'),
    beforeEnter: [restrictCouple]
  }

]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/**
 * Verifica a autenticação do usuário antes de navegar para uma determinada rota.
 * - Se for para o Logout, siga para o logout
 * - Se não estiver autenticado e não for para o login, nem contrato-code, vá para o login
 * - Se estiver autenticado e for para o login, caso for membro, vai para o /, caso for noivo, vai para o home dos noivos
 */

router.beforeEach((to) => {

  const coupleRoutes = ['/repertorio', '/contrato/code', '/sobre-nos', '/info-noivos', '/homenoivos', '/escolha'];
  const publicRoutes = ['/logout', '/login', '/login-integrantes', '/repertorio', '/contrato/code/'];
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const isMember = store.getters["auth/isMember"];
  const contractCode = to.params?.code;
  const isContractRoute = !!contractCode && to.path.includes('/contrato/code/');
  const isChangingPassword = store.getters["auth/isChangingPassword"];

  if (!publicRoutes.includes(to.path) && isChangingPassword) {
    return '/login-integrantes';
  }

  if (to.path.includes('login') && isAuthenticated && !isChangingPassword) {
    return isMember ? '/' : '/homenoivos';
  }

  if(!contractCode && to.path.includes('contrato/code')) {
    return '/login';
  }

  if (!publicRoutes.includes(to.path) && !isAuthenticated && !isContractRoute) {
    if (coupleRoutes.includes(to.path)) {
      return '/login';
    } else {
      return {name: 'login-integrantes', query: { redirect: to.path }, params: to.params};
    }
  }
})

export default router
